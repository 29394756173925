import moment, { Moment } from "moment";
import cls from "classnames";
import React, { useEffect, useState } from "react";
import { map } from "../../../utils";
import styles from "./DateSelect.module.scss";
import { OrderFieldDateValidator } from "../../../models/OrderFieldValidation";

//  Join all validation types here.
type DateValidation = OrderFieldDateValidator;

type WeekOnlyOptions = {
  /**
   * Whether the first date should be the start of the week.
   */
  startOfWeek?: boolean;
};

export type DateListProps = {
  view: Date;

  /**
   * Alias for `valueStart`.
   */
  value?: string | Date;

  valueStart?: string | Date;
  valueEnd?: string | Date;

  /**
   * `end` is set when `isRange` is true.
   */
  onChange: (start: Date | null, end?: Date | null) => void;
  isClearable?: boolean;
  isRange?: boolean;
  todayIndicator?: boolean;

  validation?: DateValidation;


  /**
   * Limits the number of visible days in the list to a week.
   */
  weekOnly?: boolean | WeekOnlyOptions;
}

/**
 * Lists selectable dates in a grid, starting with monday.
 */
export const DateList: React.FC<DateListProps> = ({
  view,
  value,
  valueStart,
  valueEnd,
  onChange,
  validation,
  todayIndicator = true,
  weekOnly = false,
  isClearable = false,
  isRange = false,
}) => {
  view = moment(view).startOf('day').toDate();
  valueStart = valueStart || value;

  const [hoveredDate, setHoveredDate] = useState<Date>(null);

  const startAsMoment = valueStart ? moment(valueStart) : null;
  const endAsMoment = valueEnd ? moment(valueEnd) : null;

  const weekOnlyOpts = weekOnly as WeekOnlyOptions;
  let startDate = moment(view);

  if (!weekOnly || weekOnlyOpts.startOfWeek) {
    startDate = startDate.startOf(weekOnly ? 'week' : 'month');

    while (startDate.isoWeekday() !== 1) {
      startDate = startDate.subtract(1, 'day');
    }
  }

  let endDate = moment(view).endOf('month');
  while (endDate.isoWeekday() !== 7) {
    endDate = endDate.add(1, 'day');
  }

  const hoveredDateIsBeforeStart = hoveredDate && hoveredDate < startAsMoment?.toDate();
  const hoveredDateIsAfterStart = hoveredDate && hoveredDate > startAsMoment?.toDate();

  return (
    <div
      className={styles.listContainer}
      onMouseLeave={() => setHoveredDate(null)}>
      <header className={styles.weekDayList}>
        {
          map(7, offset => (
            <div key={offset} className={styles.weekDayListItem}>
              {moment(startDate).add(offset, 'day').format('dd')}
            </div>
          ))
        }
      </header>
      <div role="list" className={styles.dateList}>
        {
          map(weekOnly ? 7 : Math.ceil(endDate.diff(startDate, 'days') + 1), offset => {
            const date = moment(startDate).add(offset, 'days');
            const isToday = date.format('L') === moment().format('L');
            const isSelectedAsStart = startAsMoment && startAsMoment.format('L') === date.format('L');
            const isSelectedAsEnd = endAsMoment && endAsMoment.format('L') === date.format('L');
            const isSelected = isSelectedAsStart || isSelectedAsEnd;

            let isIntermediate = isRange && date.isBefore(endAsMoment) && date.isAfter(startAsMoment);
            if (hoveredDateIsBeforeStart) {
              isIntermediate = isRange && date.isBefore(endAsMoment || startAsMoment) &&
                date.isAfter(hoveredDate);

            } else if (hoveredDateIsAfterStart) {
              isIntermediate = isRange && date.isBefore(hoveredDate) && date.isAfter(startAsMoment);

            }

            const isSelectable = validation ? isDateSelectable(date, validation) : true;

            return (
              <div
                key={date.toISOString()}
                className={cls([
                  styles.dateItem,
                  date.month() !== view.getMonth() ? styles.otherMonth : null,
                  isToday && todayIndicator ? styles.today : null,
                  isSelected ? styles.selected : null,
                  isIntermediate ? styles.intermediate : null,
                  !isSelectable ? styles.disabled : null,
                ])}
                role="button"
                onMouseEnter={() => setHoveredDate(date.toDate())}
                onMouseLeave={() => setHoveredDate(previousDate => date.isSame(previousDate) ? null : previousDate)}
                onClickCapture={() => {
                  if (!isSelectable) {
                    return;
                  }

                  const clearStart = isSelectedAsStart && isClearable;
                  const clearEnd = isSelectedAsEnd && isClearable;
                  const setStart = valueStart && isRange && date.isBefore(startAsMoment);
                  const setEnd = valueStart && isRange && date.isAfter(startAsMoment);
                  const setStartKeepTime = Boolean(valueStart);

                  if (clearStart && clearEnd) {
                    //  When start = end, and we click the element to clear.
                    onChange(null, null);
                  } else if (clearStart && endAsMoment) {
                    //  End date should become start date.
                    //  There shouldn't be an end date without a start date.
                    onChange(endAsMoment?.toDate() ?? null, null);

                  } else if (clearStart) {
                    onChange(null, endAsMoment?.toDate() ?? null);

                  } else if (clearEnd) {
                    onChange(startAsMoment?.toDate(), null);

                  } else if (setStart) {
                    //  Clicking on a date before start, with end date = move start date, keep end date.
                    onChange(date.toDate(), (endAsMoment || startAsMoment).toDate());

                  } else if (setEnd) {
                    //  Click on date after start = set end date.
                    onChange(startAsMoment.toDate(), date.toDate());

                  } else if (setStartKeepTime) {
                    onChange(moment(date).hour(startAsMoment.hour()).minute(startAsMoment.minute()).toDate());

                  } else {
                    onChange(date.toDate());

                  }
                }}>
                {date.date()}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export function getDateValidationBounds(validation: DateValidation | null | undefined) {
  return {
    //  .days can be null if user cleared the field.
    after: typeof validation?.afterNow?.days === 'number' ? moment()
      .add(validation.afterNow.days, 'days') : null,

    before: typeof validation?.beforeNow?.days === 'number' ? moment()
      .add(validation.beforeNow.days, 'days') : null,
  };
}

function isDateSelectable(date: Moment, validation: DateValidation) {
  const { after, before } = getDateValidationBounds(validation);

  if (after && date.isBefore(
    after, 'date'  //  Compare date, not time. { days: 0 } should allow the current date.
  )) {
    return false;
  }

  if (before && date.isSameOrAfter(
    before, 'date'
  )) {
    return false;
  }

  return true;
}