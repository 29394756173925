import React, { useRef, useEffect, useState, useContext, CSSProperties } from 'react'
import cls from 'classnames'
import { isDateValid, isEnterKey, isTabKey, nullIfNaN } from '../../../utils';
import { createPortal } from 'react-dom';
import { HideTooltip, TooltipContext } from '../Tooltip/TooltipContextProvider';
import { text } from '../../../utils/i18n';
import roundTo from 'round-to';

type Renderer = JSX.Element | ((props?: any) => JSX.Element);

export const debounceDelayLong = 650;
export const debounceDelayShort = 400;

export interface TextInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>, 'prefix'> {
  /**
   * Invoked if only numeric characters are allowed and the input value
   * has been parsed to a numeric value.
   * 
   * Can be debounced by setting the `debounce` prop.
   */
  onNumericValueChange?: (val: number) => void;

  /**
   * Can be debounced by setting the `debounce` prop.
   * 
   * If you return a string, then the rendered value will be set to that.
   */
  onTextValueChange?: (value: string) => void | Promise<void> | string;

  multiline?: boolean;
  nodeRef?: (element: HTMLInputElement | HTMLTextAreaElement) => void;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  containerStyle?: CSSProperties;
  allow?: AllowChars;
  expandable?: boolean;
  rows?: number;
  maxRows?: number;
  renderStart?: Renderer;
  renderEnd?: Renderer;
  centerRenderedStart?: boolean;
  centerRenderedEnd?: boolean;
  containerClassName?: string;
  isLengthCounterEnabled?: boolean;
  /**
   * Whether to minimize the spacing between an aside component (prefix or suffix) and the text input.
   * @default false
   */
  minimizeAsideComponentSpacing?: boolean;
  /**
   * Optional list of placeholders to cycle through, such as value examples.
   */
  placeholders?: string[];

  /**
   * Renders an state indicating invalid input.
   */
  invalid?: boolean;

  testId?: string;

  /**
   * If true-ish then debounces `on*ValueChange` callbacks with the provided delay in milliseconds.
   * Set to `true` for default value of 650.
   */
  debounce?: number | boolean;

  allowPasswordManagerAutofill?: boolean;

  /**
   * Whether to focus on the next tab index. Requires own tabIndex to be set.
   */
  focusNextOnEnter?: boolean;

  /**
   * Formats the value, visible when blurred.
   * 
   * If `true`, and `allow` is `numeric`, then the numeric value is formatted.
   * 
   * @default true
   */
  formatBlurredValue?: true | false | ((value: string | string[] | number) => string);

  /**
   * Whether to format the value, if blurred, and if the value evaluates to false.
   * 
   * @default true
   */
  formatBlurredEmptyValue?: boolean;
}

function restProps(props: TextInputProps) {
  let rest: any = { ...props }

  rest['data-testid'] = props.testId;

  if (!props.allowPasswordManagerAutofill) {
    rest['data-lpignore'] = "true"; // Disable Lastpass autofill.
  }

  return rest
}

function areCharsAllowed(e: React.KeyboardEvent, allow: AllowChars) {
  const { keyCode } = e

  if (e.ctrlKey || e.metaKey) {
    return true;
  }

  //  check special keys
  if ([8, 9, 13, 37, 38, 39, 40].indexOf(keyCode) != -1) {
    return true
  }

  switch (allow) {
    case 'numeric':
    case 'numericNegative':
    case 'numericSpecial':
      if (e.shiftKey) {
        return false;
      }

      //  check decimal point
      if ([188, 190].indexOf(keyCode) != -1) {
        return true
      }

      //  +, - and space
      if (allow == 'numericSpecial' && [187, 189, 32].indexOf(keyCode) != -1) {
        return true;
      }

      //  -
      if (allow === 'numericNegative' && keyCode === 189) {
        return true;
      }

      //  check digits
      if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105))) {
        return false
      }
  }

  return true
}

export type AllowChars = 'all' | 'numeric' | 'numericNegative' | 'numericSpecial';

/**
 * Standard text input.
 */
export const TextInput: React.FC<TextInputProps> = ({
  allow,
  nodeRef,
  expandable,
  renderStart,
  renderEnd,
  prefix,
  suffix,
  focusNextOnEnter,
  invalid,
  maxRows,
  debounce,
  containerClassName,
  placeholder,
  placeholders,
  containerStyle,
  minimizeAsideComponentSpacing,
  value,
  ...props
}) => {
  const tooltipContext = useContext(TooltipContext);

  expandable = expandable || maxRows != null;

  if (props.type === "date" && value) {
    try {
      const valueAsDate = new Date(value as string);
      if (isDateValid(valueAsDate)) {
        //  Format accepted by browser date picker.
        value = valueAsDate.toISOString().substring(0, 10);
      }
    } catch (error) {}
  }

  const lineHeight = 22;

  const isNumeric = allow === "numeric" || allow === "numericNegative";
  let debounceDelay: number = debounce as any || null;

  if (debounceDelay as any === true) {
    debounceDelay = 650;
  }

  if (props.type == 'number') {
    throw new Error('use `allow="numeric"` instead of `type="number"`');
  }

  let RenderAsideComponentBefore = renderStart;
  let RenderAsideComponentAfter = renderEnd;

  if (!RenderAsideComponentBefore && prefix) {
    RenderAsideComponentBefore = () => (
      <p className="prefix">{prefix}</p>
    );
  }

  if (!RenderAsideComponentAfter && suffix) {
    RenderAsideComponentAfter = () => (
      <p className="suffix">{suffix}</p>
    );
  }

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const lineMeasureRef = useRef<HTMLDivElement>(null);
  const hideLengthCounterTooltipRef = useRef<HideTooltip>(null);
  const containerElementRef = useRef<HTMLDivElement>(null);
  const onNumericValueChangeTimeout = useRef(null);
  const onTextValueChangeTimeout = useRef(null);

  //  used for styling purposes (parent container)
  const [focused, setFocused] = useState(false);
  const [cycledPlaceholderIndex, setCycledPlaceholderIndex] = useState<number>(null);
  const [cycledPlaceholderActive, setCycledPlaceholderActive] = useState<boolean>(placeholders?.length > 0); // Used for appear / disappear animation.

  useEffect(() => {
    //  When placeholder index changes, inactive the current placeholder after a while.
    const inactiveTimeout = setTimeout(() => setCycledPlaceholderActive(false), 3500);
    return () => { clearTimeout(inactiveTimeout); }
  }, [cycledPlaceholderIndex]);

  useEffect(() => {
    if (!placeholders?.length) {
      return;
    }

    if (!cycledPlaceholderActive) {
      const cycleTimeout = setTimeout(() => {
        setCycledPlaceholderIndex(index => index + 1);
        setCycledPlaceholderActive(true);
      }, 1000);
      return () => { clearTimeout(cycleTimeout); }
    }

  }, [cycledPlaceholderActive, placeholders?.length]);

  const cycledPlaceholder = placeholders?.length ? placeholders[cycledPlaceholderIndex % placeholders.length] : null;
  if (!placeholder) {
    placeholder = cycledPlaceholder;
  }

  function getDefaultRows() {
    const valueRowCount = measureRows(inputRef.current);
    const minimumRows = props.rows ? Math.max(props.rows, valueRowCount) : valueRowCount;

    return maxRows ? Math.min(minimumRows, maxRows) : minimumRows;
  }

  const [rows, setRows] = useState(getDefaultRows());

  //  needed if debounced.
  const [textValue, setTextValue] = useState(String(value || ''));

  useEffect(() => {
    setTextValue(String(value || ''));
    setRows(getDefaultRows());
  }, [value])

  function doOnTextValueChange(value: string) {
    const result = props.onTextValueChange(value);

    if (typeof result === 'string') {
      setTextValue(result);
    }
  }

  function handleFocusOnNextInput(e: React.KeyboardEvent) {
    if (!focusNextOnEnter || (!isEnterKey(e) && !isTabKey(e))) {
      return false
    }

    let { tabIndex } = props
    if (typeof tabIndex !== "number") {
      return false
    }

    let focusedElement = document.querySelector(`[tabindex="${tabIndex + 1}"]`) as HTMLElement
    if (focusedElement) {
      focusedElement.focus()
      e.preventDefault()
      return true
    }

    return false
  }

  function handleOnTextValueChange(value: string) {
    setTextValue(value);

    if (typeof props.onTextValueChange !== 'function') {
      return;
    }

    if (debounceDelay) {
      clearTimeout(onTextValueChangeTimeout.current);
      onTextValueChangeTimeout.current = setTimeout(() => doOnTextValueChange(value), debounceDelay);
    } else {
      doOnTextValueChange(value);
    }
  }

  function handleOnNumericValueChange(value: number) {
    if (typeof props.onNumericValueChange !== 'function') {
      return;
    }

    if (debounceDelay) {
      clearTimeout(onNumericValueChangeTimeout.current);
      onNumericValueChangeTimeout.current = setTimeout(props.onNumericValueChange.bind(null, value),
        debounceDelay);
    } else {
      props.onNumericValueChange(value);
    }
  }

  /**
   * Measures the height of the current value in relation to `lineHeight` to determine the row count.
   * @returns Row count.
   */
  function measureRows(element: HTMLInputElement | HTMLTextAreaElement): number {
    const measuredValue = element?.value || String(value);

    if (!measuredValue) {
      return 1;
    }

    if (!lineMeasureRef.current) {
      const hardRows = (measuredValue.match(/\n/g) || []).length + 1;
      return hardRows;
    }

    const bounds = element.getBoundingClientRect();

    const measurer = lineMeasureRef.current;
    measurer.style.width = bounds.width + 'px';
    measurer.innerText = measuredValue.replace(/\n$/, '\n|'); //  Make sure any trailing new line accumulates space

    //  Why we use roundTo: .height can be 44.00390625 resulting in 3 lines.
    const measuredRows = Math.ceil(roundTo(measurer.getBoundingClientRect().height, 1) / lineHeight);
    return measuredRows;
  }

  const defaultMaxLength = props.multiline ? 1024 : 240;
  const maxLength = props.maxLength === undefined ? defaultMaxLength : props.maxLength;

  let renderedValue = textValue;
  if (renderedValue === undefined) {
    renderedValue = null;
  }
  renderedValue = !focused ? formatBlurredValue({ ...props, value: renderedValue }) : renderedValue;

  const rowMeasurer = expandable ? createPortal((
    <p
      style={{ lineHeight: lineHeight + 'px' }}
      ref={e => lineMeasureRef.current = e}>
    </p>
  ), document.getElementById('helper')) : null;

  function onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let value: string = event.currentTarget.value
    const { currentTarget } = event;

    if (isNumeric) {
      value = clampNumericValue(value, props.min as number, props.max as number);

      handleOnNumericValueChange(nullIfNaN(
        parseFloat(
          value.replace(/\,/, '.')
        ))
      );
    }

    handleOnTextValueChange(value);
    const { selectionStart, selectionEnd } = currentTarget;

    props.onChange?.(event);

    setTimeout(() => {
      try {
        currentTarget.selectionStart = selectionStart;
        currentTarget.selectionEnd = selectionEnd;
      } catch (e) {
        //  not supported on some types
      }
    }, 0);
  }

  useEffect(() => {
    if (props.isLengthCounterEnabled && props.maxLength &&
      typeof renderedValue === 'string' && (renderedValue.length / props.maxLength >= 0.8) && focused &&
      containerElementRef.current) {
      const bounds = containerElementRef.current.getBoundingClientRect();

      //  Use a tooltip to avoid element layout related problems.
      //  For example, in the comment list a counter below the text input
      //  would get cut-off when the text input is positioned at the bottom.
      hideLengthCounterTooltipRef.current = tooltipContext?.show(
        {
          left: bounds.left + bounds.width / 2,
          top: bounds.bottom,
          position: "bottom",
          children: text`${renderedValue.length} av ${props.maxLength} tecken`,
          delay: false
        }
      );
    } else {
      hideLengthCounterTooltipRef.current?.();
    }
  }, [props.isLengthCounterEnabled, props.maxLength, renderedValue?.length, focused]);

  useEffect(() => {
    return () => {
      hideLengthCounterTooltipRef.current?.();
    }
  }, []);

  containerStyle = containerStyle ?? {};
  if (props.multiline && RenderAsideComponentAfter == null && RenderAsideComponentBefore == null) {
    //  use column direction to have input/textarea fill parent on iOS 
    containerStyle = {
      ...containerStyle,
      flexDirection: containerStyle.flexDirection ?? 'column'
    };
  }

  return (
    <div
      ref={element => containerElementRef.current = element}
      className={cls([
        "text-input-container input-like",
        focused ? "focus" : null,
        props.disabled ? "disabled" : null,
        invalid ? "invalid" : null,
        containerClassName,
        cycledPlaceholder ? "has-cycled-placeholder" : null,
        cycledPlaceholderActive ? "is-cycled-placeholder-active" : null,
        minimizeAsideComponentSpacing ? "minimize-aside-component-spacing" : null
      ])}
      style={containerStyle}>
      {
        RenderAsideComponentBefore ? (
          <div className={cls({ "text-input-aside": true, "flex-center": props.centerRenderedStart })}>
            {typeof RenderAsideComponentBefore == 'function' ? (
              <RenderAsideComponentBefore />
            ) : (RenderAsideComponentBefore)}
          </div>
        ) : null
      }

      {
        props.multiline ? (
          //  @ts-ignore
          <textarea
            ref={e => {
              inputRef.current = e;
              nodeRef?.(e);
            }}
            {...restProps(props)}
            style={{ ...props.style, width: '100%' }}
            value={textValue}
            rows={expandable ? rows : undefined}
            maxLength={maxLength}
            onFocus={e => {
              props.onFocus?.(e as any)
              setFocused(true)
            }}
            onBlur={e => {
              props.onBlur?.(e as any)
              setFocused(false)
            }}
            onKeyDown={e => {
              if (isEnterKey(e)) {
                e.stopPropagation();
              }

              handleFocusOnNextInput(e)
              props.onKeyDown?.(e as any)
              if (!areCharsAllowed(e, allow)) {
                //  non-allowed chars
                e.preventDefault()
                return true;
              }
            }}
            onKeyUp={e => {
              if (isEnterKey(e)) {
                //  Prevents a save-like action on enter onKeyUp. 
                e.stopPropagation();
              }
            }}
            onChange={event => {
              if (!expandable) {
                props.onChange?.(event)
                return
              }

              const { currentTarget } = event;

              let nextRows = measureRows(currentTarget);

              if (props.rows) {
                nextRows = Math.max(nextRows, props.rows)
              }

              if (maxRows > 0) {
                nextRows = Math.min(nextRows, maxRows)
              }

              if (rows !== nextRows) {
                setRows(nextRows);
              }

              onChange(event);
            }}
            placeholder={placeholder} />
        ) : (
          <input
            type="text"
            ref={e => {
              inputRef.current = e;
              nodeRef?.(e);
            }}
            {...restProps(props)}
            style={{ ...props.style, width: '100%' }}
            value={renderedValue}
            onChange={onChange}
            maxLength={maxLength}
            onFocus={e => {
              props.onFocus?.(e as any)
              setFocused(true)
            }}
            onBlur={e => {
              props.onBlur?.(e as any)
              setFocused(false)

              if (isNumeric && !renderedValue && !debounceDelay) {
                setTextValue(value as string);
              }
            }}
            onKeyDown={e => {
              handleFocusOnNextInput(e)
              props.onKeyDown?.(e as any)

              const pass = areCharsAllowed(e, allow);

              if (!pass) {
                //  non-allowed chars
                e.preventDefault()
                return true;
              }
            }}
            placeholder={placeholder}
          />
        )
      }

      {
        RenderAsideComponentAfter ? (
          <div className={cls({ "text-input-aside": true, "flex-center": props.centerRenderedEnd })}>
            {typeof RenderAsideComponentAfter == 'function' ? (
              <RenderAsideComponentAfter />
            ) : (RenderAsideComponentAfter)}
          </div>
        ) : null
      }

      {rowMeasurer}
    </div>
  )
}

TextInput.defaultProps = {
  allow: 'all',
  formatBlurredValue: true,
  formatBlurredEmptyValue: true
}

/**
 * Returns the value to render while the input is blurred.
 * @param props Props to derive from. You may override the `value`.
 * @returns Formatted string to render in a blurred state.
 */
export function formatBlurredValue(props: TextInputProps) {
  const { value } = props;

  let { formatBlurredValue } = props;
  if (formatBlurredValue === true && (props.allow === 'numeric' || props.allow === 'numericNegative')) {
    formatBlurredValue = formatNumeric;
  }

  const shouldFormatBlurredValue = (props.formatBlurredEmptyValue ? true : !!props.value)
    && typeof formatBlurredValue === "function";

  if (!shouldFormatBlurredValue) {
    return value;
  }

  return (formatBlurredValue as any)(value);
}

export function formatNumeric(value: string | string[] | number, maximumFractionDigits = 2) {
  return isNaN(value as any) ? '0' : Number(value as string)
    .toLocaleString('sv', { maximumFractionDigits });
}

/**
 * Clamps a value within `min` and `max` bounds if they are set.
 * @param value The input's value.
 * @returns The clamped value.
 */
function clampNumericValue(value: string, min?: number, max?: number): string {
  const valueAsNumeric = Number(value);

  if (typeof max === "number" && valueAsNumeric > max) {
    return String(max);
  }

  if (typeof min === "number" && valueAsNumeric < min) {
    return String(min);
  }

  return value;
}