/**
 * A list of country name and ISO-3166 2-letter lowercase code.
 * 
 * Must be in lowercase.
 * @see https://www.iban.com/country-codes
 */
export enum Country {
  Sweden = 'se',
  Norway = 'no',
  Denmark = 'dk',
  Finland = 'fi'
}

/**
 * Returns the international phone number prefix, by country.
 */
export function getPhoneNumberPrefixByCountry(country: Country): string {
  return {
    [Country.Sweden]: '+46',
    [Country.Norway]: '+47',
    [Country.Denmark]: '+45',
    [Country.Finland]: '+358'
  }[country] || '';
}