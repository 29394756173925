import { createContext, useState } from "react"
import { Tooltip, TooltipProps } from "./Tooltip";
import { createTemporaryId } from "../../../utils/model";

export type HideTooltip = () => void;

type TooltipLocation = { left: number; top: number; };
type TooltipState = TooltipProps & TooltipLocation & { id: string; };

/**
 * Allows for controlling the on-screen tooltip.
 */
export const TooltipContextProvider: React.FC = ({ children }) => {
  const [tooltip, setTooltip] = useState<TooltipState | null>(null);

  return (
    <TooltipContext.Provider value={{
      show(tooltip) {
        const id = createTemporaryId();

        setTooltip(previous => {
          return {
            ...tooltip,
            visible: true,
            id,
            left: tooltip.left ?? previous?.left ?? 0,
            top: tooltip.top ?? previous?.top ?? 0,
          }
        });

        return () => {
          setTooltip(tooltip => {
            if (tooltip.id === id) {
              return { ...tooltip, visible: false };
            }

            return tooltip;
          });
        }
      },
    }}>
      {children}
      <Tooltip style={{ ...tooltip?.style, left: tooltip?.left, top: tooltip?.top }} {...tooltip} />
    </TooltipContext.Provider>
  )
}


export const TooltipContext = createContext<{
  show: (tooltip: Omit<TooltipProps, 'visible'> & Partial<TooltipLocation>) => HideTooltip;
}>(null);