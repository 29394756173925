import { text } from "../utils/i18n";

/**
 * Contains more information about a specific feature.
 */
export class FeatureMetadata {
  readonly feature: Feature;
  readonly name: string;
  readonly description: string;

  constructor(deriveFrom?: Partial<FeatureMetadata>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }
}

/**
 * A feature, if enabled, hides or shows parts of the app for a more minimalistic and tailored user experience.
 */
export enum Feature {
  Contact = 'contact',
  Dial = 'dial',
  Offer = 'offer',
  Zavann = 'zavann',
  Events = 'events',
  Calendar = 'calendar',
  Project = 'project',
  RegistrationLink = 'reglink',
  Dashboard = 'dashboard',
  OfferWebForm = 'offerwebform',
}

export const allFeatures = Object.values(Feature);

/**
 * Information about an enabled
 */
export class EnabledFeature {
  feature: Feature;
  since: Date = new Date();

  constructor(deriveFrom?: Partial<EnabledFeature>) {
    if (!deriveFrom) {
      return;
    }

    Object.assign(this, deriveFrom);

    if (this.since) {
      this.since = new Date(this.since);
    }
  }
}

/**
 * All features which an administrator can enable or disable by their own.
 */
export const selectableFeatures: FeatureMetadata[] = [
  new FeatureMetadata({
    feature: Feature.Offer,
    name: text`[plural] Avtal`,
    description: 'Skicka avtal via e-post och SMS'
  }),
  new FeatureMetadata({
    feature: Feature.Dial,
    name: text`[plural] Samtal`,
    description: 'Ring samtal automatiskt'
  }),
  new FeatureMetadata({
    feature: Feature.Contact,
    name: text`Kontakter`,
    description: 'Samla eller ring kontakter'
  }),
  new FeatureMetadata({
    feature: Feature.Events,
    name: text`Aktivitet`,
    description: "Se företagets aktivitet"
  }),
  new FeatureMetadata({
    feature: Feature.Calendar,
    name: text`Kalender`,
    description: "Se och skapa användares kalender"
  }),
  new FeatureMetadata({
    feature: Feature.Project,
    name: text`[plural] Projekt`,
    description: "Gruppera inställningar och dokument"
  }),
  new FeatureMetadata({
    feature: Feature.RegistrationLink,
    name: text`Registreringslänkar`,
    description: "Användare kan registrera sig själva via länk"
  }),
  new FeatureMetadata({
    feature: Feature.Dashboard,
    description: "",
    name: text`Statistik`
  }),
  new FeatureMetadata({
    feature: Feature.OfferWebForm,
    name: text`Avtalsformulär`,
    description: "Skapa avtal smidigt från er hemsida"
  })
];