import React, { useRef, useState, useEffect, ReactNode } from 'react'
import cls from 'classnames'
import './styles.scss'
import { eq, isEscapeKey } from '../../../utils';
import { insertEventListener, removeIndexedEventListener } from '../../../utils/events';
import { createCloseAsideButton, PopoverHead } from './PopoverHead';
import styles from "./Popover.module.scss";
import { createTemporaryId } from '../../../utils/model';
import { createPortal } from 'react-dom';
import { isEventFromCurrentTarget } from '../../../utils/dom';

export interface BasePopoverProps {
  open?: boolean;
  onClose?: () => void;
  onHidden?: () => void;
}

type ParentElement = HTMLElement | Window | VisualViewport;
type ParentElementOverride = { left?: number; top?: number; width?: number; height?: number; scale?: number; };
export type HorizontalPosition = 'left' | 'center' | 'right';
export type VerticalPosition = 'top' | 'bottom' | 'middle';

const paddingInPx = 24;

export interface PopoverProps extends BasePopoverProps {
  children?: any;
  className?: string;
  bodyClassName?: string;

  /**
   * Whether to hide the popover by clicking outside of it,
   */
  dismissOutside?: boolean;

  closeButton?: boolean;
  /**
   * If set, then `horizontalPosition` and `verticalPosition` will automatically be adjusted to bring
   * the popover closer to the center of the set element.
   * To not dynamically set a position, pass a fixed value. For example, setting `horizontalPosition` will not
   * automatically adjust the horizontal position.
   */
  positionCenterOf?: ParentElement;

  /**
   * Overrides values in `positionCenterOf`. This can be used, for example, if a window-fixed container
   * contains a popover which should align with `window.visualViewport`, then a left and top of 0 is desired.
   * In this case, we cannot instantiate a clone of `window.VisualViewport`.
   */
  positionCenterOfOverride?: ParentElementOverride;
  horizontalPosition?: HorizontalPosition;
  verticalPosition?: VerticalPosition;
  /**
   * Whether to automatically adjust `horizontalPosition` or `verticalPosition` to fit the viewport.
   * @default true
   */
  adjustPosition?: boolean;
  title?: ReactNode;

  /**
   * ID of this popover. It can be anything, such as a string,
   * but it must differentiate from other, potentially parallel popovers.
   * 
   * A quick and easy example of a popover ID is the containing component's name.
   */
  popoverId: any;

  /**
   * Optional ID of a parent popover. Opening this popover will not
   * close the parent popover IDs.
   * 
   * Relevant when the child popover has `usePortal` set to true while the parent does not,
   * thus the child not being a DOM descendant. Otherwise, the child popover will not automatically close
   * the parent popover.
   */
  parentPopoverId?: any;

  /**
   * Whether to render the popover in a separate container.
   * @todo Make `true` the standard value, confirm other popovers are not broken 
   */
  usePortal?: boolean;

  animationType?: 'normal' | 'simple';
}

type OpenPopover = {
  id: string;
  onClose: () => void;
};

const windowState = window as typeof window & {
  openPopovers: OpenPopover[];
};
windowState.openPopovers = [];

function closeLastOpenPopover() {
  if (!windowState.openPopovers.length) {
    return;
  }

  const count = windowState.openPopovers.length;

  windowState.openPopovers[count - 1].onClose?.();
  windowState.openPopovers = windowState.openPopovers.slice(0, count - 1);
}

function containsParentPopoverWithId(target: HTMLElement, parentPopoverId: string) {
  let parentElement: HTMLElement = target.parentElement;

  while (parentElement) {
    if (parentElement.getAttribute('data-popover-id') === parentPopoverId) {
      return true;
    }

    parentElement = parentElement.parentElement;
  }

  return false;
}

document.body.addEventListener('keyup', e => {
  if (isEscapeKey(e as any)) {
    closeLastOpenPopover();
  }
});

const nonModalRoot = document.getElementById('popovers');
const modalRoot = document.getElementById('modal-popovers');

/**
 * Basic popover.
 * 
 * Once open, the popover will close upon a click anywhere outside of the popover element.
 * To prevent closing a popover upon click, set a `data-keep-popover-open` attribute on that element,
 * then clicking it will not cause popovers to close. The popover itself has this attribute.
 */
export const BasicPopover: React.FC<PopoverProps> = ({ usePortal, ...props }) => {
  const [isContainedInModal, setContainedInModal] = useState(false);
  const [portalContainerBounds, setPortalContainerBounds] = useState<DOMRect>(null);

  if (usePortal) {
    return (
      <div
        ref={element => {
          if (!element) {
            return;
          }

          const bounds = element.getBoundingClientRect();
          setPortalContainerBounds(previousBounds => eq(bounds, previousBounds) ? previousBounds : bounds);
          setContainedInModal(Boolean(getModalParentElement(element)));
        }}>
        {
          portalContainerBounds ? createPortal((
            <div style={{
              position: 'absolute',
              left: (portalContainerBounds.left + portalContainerBounds.width / 2) + 'px',
              top: (portalContainerBounds.top + portalContainerBounds.height / 2 - document.body.getBoundingClientRect().top) + 'px'
            }}>
              <PopoverElement {...props} />
            </div>
          ), isContainedInModal ? modalRoot : nonModalRoot) : null
        }
      </div>
    );
  }

  return (
    <PopoverElement {...props} />
  )
}

function getModalParentElement(child: HTMLElement) {
  let parentElement = child.parentElement;
  while (parentElement) {
    if (parentElement.hasAttribute('data-i-am-a-modal')) {
      return parentElement;
    }

    parentElement = parentElement.parentElement;
  }

  return null;
}

const PopoverElement: React.FC<PopoverProps> = ({
  bodyClassName,
  children,
  closeButton = true,
  dismissOutside = true,
  positionCenterOf,
  positionCenterOfOverride,
  onClose,
  onHidden,
  open,
  title,
  className,
  popoverId,
  parentPopoverId,
  horizontalPosition,
  verticalPosition,
  animationType = "normal",
  adjustPosition = true,
}) => {
  if (!verticalPosition && !horizontalPosition) {
    verticalPosition = "bottom";
  }

  const bodyRef = useRef<HTMLDivElement>(null);
  const transformedContainerRef = useRef<HTMLDivElement>(null);
  const idRef = useRef(popoverId || createTemporaryId());
  const firedOnHidden = useRef(false);

  function updateBodyPosition() {
    const element = bodyRef.current;
    if (!element) {
      return;
    }

    const container = element.parentElement.parentElement;

    let { horizontalPosition: nextHorizontalPosition, verticalPosition: nextVerticalPosition, x, y, viewportScale } = getPosition(
      positionCenterOf, positionCenterOfOverride, element, horizontalPosition, verticalPosition);
    element.style.transform = `translate(${x}, ${y})`;
    container.setAttribute('data-horizontal-position', nextHorizontalPosition);
    container.setAttribute('data-vertical-position', nextVerticalPosition);

    if (adjustPosition) {
      const limitedBounds = limitBoundsToVisualViewport(element, x, y, nextHorizontalPosition, nextVerticalPosition);
      element.style.transform = `translate(${limitedBounds.x}, ${limitedBounds.y})`;
      container.setAttribute('data-horizontal-position', limitedBounds.horizontalPosition);
      container.setAttribute('data-vertical-position', limitedBounds.verticalPosition);

      x = limitedBounds.x;
      y = limitedBounds.y;
      nextHorizontalPosition = limitedBounds.horizontalPosition;
      nextVerticalPosition = limitedBounds.verticalPosition;
    }

    const containerLimitedBounds = limitBodyBoundsToContainer(element, x, y, viewportScale, nextHorizontalPosition, nextVerticalPosition);
    element.style.transform = `translate(${containerLimitedBounds.x}, ${containerLimitedBounds.y})`;
  }

  useEffect(() => {
    if (!open) {
      return;
    }

    const positionListener = (event: Event) => {
      updateBodyPosition();
    }

    if (window.VisualViewport && positionCenterOf instanceof window.VisualViewport) {
      positionCenterOf.addEventListener('resize', positionListener);
      positionCenterOf.addEventListener('scroll', positionListener);
    }

    window.addEventListener('scroll', positionListener);
    return () => {
      if (window.VisualViewport && positionCenterOf instanceof window.VisualViewport) {
        positionCenterOf.removeEventListener('resize', positionListener);
        positionCenterOf.removeEventListener('scroll', positionListener);
      }

      window.removeEventListener('scroll', positionListener);
    }
  }, [open, positionCenterOf]);

  //  For closing this popover if clicked outside of it
  useEffect(() => {
    if (open) {
      firedOnHidden.current = false;
    }

    if (open && dismissOutside) {
      //  Close other already open popovers unless it would close itself.
      windowState.openPopovers = windowState.openPopovers.filter(popover => {
        if (popover.id !== idRef.current &&
          !containsParentPopoverWithId(bodyRef.current, popover.id) &&
          (!parentPopoverId || parentPopoverId !== popover.id)) {
          popover.onClose?.();
          return false;
        }

        return true;
      });

      windowState.openPopovers.push({
        id: idRef.current,
        onClose
      });

      const dismissableListener = (event: MouseEvent) => {
        const elementPath = event.composedPath() as HTMLElement[];
        if (elementPath.find(element => element.getAttribute?.('data-keep-popover-open'))) {
          return true;
        }

        //  Not sure why this is, but when clicking on a Select in DatePopover, the element path is html, document, body,
        //  and therefor the popover closes immediately.
        if (elementPath[0] === document.body) {
          return;
        }

        event.stopPropagation()
        event.preventDefault()

        if (typeof onClose === "function") {
          onClose();

          windowState.openPopovers = windowState.openPopovers.filter(popover => popover.id !== idRef.current);
        }
      };

      //  Force the popover open for some time before being able to close it.
      const clickListenerTimeout = setTimeout(() => {
        insertEventListener('click', dismissableListener, 0);
      }, 500);

      return () => {
        clearTimeout(clickListenerTimeout);
        removeIndexedEventListener('click', dismissableListener)
      };
    }
  }, [open]);

  return (
    <div
      className={cls([
        styles.container,
        className
      ])}
      onMouseMove={e => {
        //  don't propagate to zoomable view
        e.stopPropagation()
      }}
      onTouchMove={e => {
        //  don't propagate to zoomable view
        e.stopPropagation()
      }}
      onClick={e => {
        e.stopPropagation()
      }}
      data-keep-popover-open
      data-vertical-position={verticalPosition}
      data-horizontal-position={horizontalPosition}>
      <div
        ref={element => transformedContainerRef.current = element}
        className={cls([styles.transformedContainer, open ? styles.open : null])}
        data-animation-type={animationType}
        onTransitionEnd={event => {
          if (isEventFromCurrentTarget(event) && !open && !firedOnHidden.current) {
            firedOnHidden.current = true;
            onHidden?.();
          }
        }}>
        <div
          ref={element => {
            bodyRef.current = element;
            if (element) {
              updateBodyPosition();
            }
          }}
          data-popover-body
          data-popover-id={popoverId}
          className={cls([styles.body, bodyClassName, !title ? styles.noTopSpacing : null])}>

          <PopoverHead
            asideButtons={closeButton ? [
              createCloseAsideButton(() => {
                onClose?.();
              })
            ] : []}
            title={title}
            className={styles.masterHead} />

          {
            children
          }
        </div>
        <div className={styles.notchContainer}>
          <div className={styles.notch}></div>
        </div>
      </div>
    </div>
  )
}

function getTranslateX(horizontalPosition: HorizontalPosition, verticalPosition?: VerticalPosition) {
  if (horizontalPosition === "right" && verticalPosition === "middle") {
    return "0";
  }

  if (horizontalPosition === "right") {
    return "0px";
  }

  if (horizontalPosition === "left") {
    return `calc(-100%)`;
  }

  return "-50%";
}

function getTranslateY(verticalPosition: VerticalPosition) {
  if (verticalPosition === "top") {
    return "-100%";
  }

  if (verticalPosition === "bottom") {
    return "0";
  }

  return "-50%";
}

/**
 * Calculates the position of the popover body and preferred horizontal / vertical position of the popover.
 * If `horizontalPosition` is set, then the returned `horizontalPosition` has the same value, with an `x` value
 * accordingly. If the returned `horizontalPosition` is "center", then the x value may still be adjusted to
 * more finely align the popover. Same logic applies to `verticalPosition`. 
 * @param parentElement Element to center the popover towards.
 * @param popoverBodyElement Popover body.
 * @param horizontalPosition Fixed horizontal position, if any.
 * @param verticalPosition Fixed vertical position, if any.
 */
function getPosition(
  parentElement: ParentElement | null,
  override: ParentElementOverride | null,
  popoverBodyElement: HTMLElement,
  horizontalPosition?: HorizontalPosition,
  verticalPosition?: VerticalPosition
): { x: string; y: string; verticalPosition: VerticalPosition; horizontalPosition: HorizontalPosition; viewportScale: number; } {
  let x: string | 0 = 0, y: string | 0 = 0;
  let left = 0, top = 0, width = 0, height = 0, scale = 1;

  if (parentElement && (horizontalPosition || verticalPosition)) {
    if (parentElement instanceof Window) {
      width = override?.width ?? parentElement.innerWidth;
      height = override?.height ?? parentElement.innerHeight;
    } else if (window.VisualViewport && parentElement instanceof window.VisualViewport) {
      left = override?.left ?? parentElement.offsetLeft;
      top = override?.top ?? parentElement.offsetTop;
      width = override?.width ?? parentElement.width;
      height = override?.height ?? parentElement.height;
      scale = override?.scale ?? parentElement.scale;
    } else {
      const bounds = (parentElement as HTMLElement).getBoundingClientRect();
      left = override?.left ?? bounds.left;
      top = override?.top ?? bounds.top;
      width = override?.width ?? bounds.width;
      height = override?.height ?? bounds.height;
    }

    const transformedContainer = popoverBodyElement.parentElement;
    const popoverContainer = transformedContainer.parentElement;

    const popoverBounds = popoverContainer.getBoundingClientRect();
    const popoverCenterX = popoverBounds.left;
    const popoverCenterY = popoverBounds.top;
    const parentCenterX = left + width / 2;
    const parentCenterY = top + height / 2;

    const { width: popoverWidth, height: popoverHeight } = popoverBodyElement.getBoundingClientRect();
    const targetPopoverLeft = parentCenterX - popoverWidth / 2; // Left of the popover to center it in parent.
    const targetPopoverRight = parentCenterX + popoverWidth / 2;
    const targetPopoverTop = parentCenterY - popoverHeight / 2;
    const targetPopoverBottom = parentCenterY + popoverHeight / 2;

    const distanceToLeftCenter = (targetPopoverLeft - popoverCenterX) * scale;
    const distanceToRightCenter = (targetPopoverRight - popoverCenterX) * scale;
    const distanceToTopCenter = (targetPopoverTop - popoverCenterY) * scale;

    if (!verticalPosition || verticalPosition === "middle") {
      y = Math.min(Math.max(distanceToTopCenter, -popoverHeight + paddingInPx), -paddingInPx).toLocaleString('en', { maximumFractionDigits: 1 }) + "px";
      verticalPosition = "middle";
    }

    if (!horizontalPosition) {
      //  If horizontalPosition is set, then force that value.
      //  TODO: Right now we're not aligning more finely if a center horizontalPosition is set.  
      x = distanceToLeftCenter.toLocaleString('en', { maximumFractionDigits: 1 }) + "px";
      horizontalPosition = "center";
    }
  }

  if (!x) {
    if (horizontalPosition === "right") {
      x = getTranslateX("right", verticalPosition);
    } else if (horizontalPosition === "left") {
      x = getTranslateX("left", verticalPosition);
    } else if (!x) {
      x = getTranslateX("center", verticalPosition);
    }
  }

  if (!y) {
    if (verticalPosition === "bottom") {
      y = getTranslateY("bottom");
    } else if (verticalPosition === "top") {
      y = getTranslateY("top");
    } else {
      y = getTranslateY("middle");
    }
  }

  return { x, y, verticalPosition, horizontalPosition, viewportScale: scale }
}

/**
 * Sets an optimal translate x, translate y, horizontal position and veritical position
 * to fit the popover in the visual viewport.
 */
function limitBoundsToVisualViewport(popoverBodyElement: HTMLElement, x: string, y: string, horizontalPosition: HorizontalPosition, verticalPosition: VerticalPosition) {
  const viewportMargin = 24;
  const transformedContainer = popoverBodyElement.parentElement;
  const popoverContainer = transformedContainer.parentElement;

  //  Cannot check popover body top due to appear animation.
  const { top: containerTop } = popoverContainer.getBoundingClientRect();
  const { height: bodyHeight } = popoverBodyElement.getBoundingClientRect();

  let popoverTop = 0;

  if (verticalPosition === "top") {
    popoverTop = containerTop - bodyHeight;
  }

  if (verticalPosition === "top" && popoverTop < viewportMargin) {
    if (horizontalPosition !== "left") {
      horizontalPosition = "right";
    }
    verticalPosition = "middle";

    x = getTranslateX(horizontalPosition, verticalPosition);
    y = `calc(${y} + ${Math.abs(popoverTop) + viewportMargin - 10}px)`; // Subtract a smaller margin to avoid recursion error.
  }

  return { x, y, horizontalPosition, verticalPosition };
}

function limitBodyBoundsToContainer(popoverBodyElement: HTMLElement, x: string, y: string, viewportScale: number, horizontalPosition: HorizontalPosition, verticalPosition: VerticalPosition) {
  const margin = 24 / viewportScale;

  const { left: anchorLeft, top: anchorTop, right: anchorRight, bottom: anchorBottom } = popoverBodyElement.parentElement.getBoundingClientRect();
  const { left: bodyLeft, top: bodyTop, right: bodyRight, bottom: bodyBottom, width: bodyWidth, height: bodyHeight } = popoverBodyElement.getBoundingClientRect();

  const offsetTop = bodyTop - anchorTop;
  const offsetLeft = bodyLeft - anchorLeft;
  const offsetBottom = bodyBottom - anchorBottom;
  const offsetRight = bodyRight - anchorRight;

  if (verticalPosition !== "middle" && offsetRight < margin) {
    //  Left side limit.
    x = (-bodyWidth + margin) * viewportScale + 'px';
  }

  if (verticalPosition !== "middle" && offsetLeft > -margin) {
    //  Right side limit.
    x = (-margin) * viewportScale + 'px';
  }

  if (verticalPosition === "middle" && offsetTop > -margin) {
    //  Top side limit.
    y = (-margin) * viewportScale + 'px';
  } else if (verticalPosition === "middle" && offsetBottom > bodyHeight + margin) {
    //  Bottom side limit.
    y = (-bodyHeight + margin) * viewportScale + 'px';
  }

  return { x, y };
}