export type ColorComponents = {
  /**
   * 0 to 255.
   */
  red: number;

  /**
   * 0 to 255.
   */
  green: number;

  /**
   * 0 to 255.
   */
  blue: number;

  alpha?: number;
};

export function hexToRgb(hex: string): ColorComponents {
  hex = hex.replace('#', '');

  if (hex.length === 3) {
    //  eee => eeeeee
    hex = hex.split('').reduce((string, char) => string + char + char, '');
  }

  if (hex.length !== 6) {
    throw new Error("Cannot parse hex string, must be 6 characters: " + hex);
  }

  return {
    red: parseInt(hex.substring(0, 2), 16),
    green: parseInt(hex.substring(2, 4), 16),
    blue: parseInt(hex.substring(4, 6), 16),
  };
}

export function getRgb(color: string): ColorComponents {
  if (color.startsWith('#')) {
    return hexToRgb(color);
  }

  if (/^rgba?\(/.test(color)) {
    const [_, red, green, blue, alpha] = /rgba?\(([\d]+),\s?([\d]+),\s?([\d]+)(?:,\s?([\d\.]+))?\)/.exec(color);

    return {
      red: red ? Number(red) : 0,
      green: green ? Number(green) : 0,
      blue: blue ? Number(blue) : 0,
      alpha: alpha ? Number(alpha) : 0
    };
  }

  throw new Error("Could not parse color: " + color);
}

export function isLightColor(color: string): boolean {
  const { red, green, blue } = getRgb(color);


  //  See https://stackoverflow.com/a/12043228
  const luma = 0.2126 * red + 0.7152 * green + 0.0722 * blue;

  //  According to the material design color page,
  //  #757575 is dark (luma: 117)
  //  #9E9E9E is light (luma: 158)
  //  However, we use a greater threshold since the design is not considered accessibility-first.
  return luma > 180;
}