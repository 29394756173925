/**
 * Adds tags to disable SEO.
 */
export function disableSeo() {
  //  See https://www.woorank.com/en/edu/seo-guides/meta-robots-tag-seo
  for (const name of ['robots', 'googlebot', 'googlebot-news', 'slurp', 'msnbot']) {
    const tag = document.createElement('meta');
    tag.name = name;
    tag.content = 'noindex, nofollow';

    document.head.appendChild(tag);
  }
}