import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { text } from '../../../utils/i18n';
import { Button } from '../Button/Button';
import { TooltipTrigger } from '../Tooltip/TooltipTrigger';
import styles from './error_screen_wrapper.module.scss';

type Props = {
  error: any;
  onExpandChange?: (expanded: boolean) => void;
}

/**
 * Renders an error description and header.
 * @author Johan Svensson
 */
export default function ErrorInfo({ error, onExpandChange }: Props) {
  const stack = typeof error?.stack === 'string' ? error.stack?.split('\n') : error?.stack;
  const firstStackTrace = stack?.[1];

  const [expanded, setExpanded] = useState(false);

  if (!error) {
    return null;
  }

  return (
    <div className={styles.technical}>
      <h3>
        {text`Teknisk information`}&nbsp;
        <TooltipTrigger tooltip={expanded ? text`Dölj` : text`Visa`}>
          <Button
            icon={expanded ? "chevron-up" : "chevron-down"}
            onClick={() => setExpanded(e => { onExpandChange?.(!e); return !e; })} />
        </TooltipTrigger>
      </h3>
      <Collapse isOpened={expanded}>
        <p><code>{error.name} {typeof error.message === 'string' ? error.message : ''} {firstStackTrace}</code></p>
      </Collapse>
    </div>
  )
}