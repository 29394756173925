import React, { CSSProperties, useEffect, useRef } from "react";
import cls from "classnames";
import styles from "./Tooltip.module.scss";
import { createPortal } from "react-dom";

export type TooltipPosition = 'left' | 'top' | 'bottom' | 'right';

export type TooltipProps = {
  position?: TooltipPosition;
  children?: any;
  visible?: boolean;
  style?: CSSProperties;
  delay?: boolean;
  /**
   * Set this to true to fix newlines as the tooltip approaches the right viewport edge.
   */
  fixWidth?: boolean;
};

/**
 * Renders a tooltip.
 */
export const Tooltip: React.FC<TooltipProps> = ({ children, position, style, delay, fixWidth = false, visible = false }) => {
  position = position ?? "bottom";
  delay = delay ?? true;
  style = style ?? {};

  const elementRef = useRef<HTMLDivElement>(null);

  /**
   * Positions the tooltip so that it does not overflow the screen.
   * @param element Element to position in the screen.
   * @param iterations Number of times to re-do the fitting, to prevent a stack overflow. Internal, do not touch.
   */
  function fitInScreen(element: HTMLDivElement, iterations = 4) {
    const bounds = element.getBoundingClientRect();
    const parentElement = element.parentElement;

    const minLeft = 12, minTop = 12, minBottom = 12, minRight = 12;

    const bottom = parentElement.clientHeight - bounds.bottom;
    const right = parentElement.clientWidth - bounds.right;

    let didChange = false;

    if (bounds.left < minLeft && element.style.left) {
      element.style.left = `calc(${element.style.left} + ${minLeft - bounds.left}px)`;
      didChange = true;
    } else if (bounds.left < minLeft) {
      element.style.left = `${minLeft - bounds.left}px`;
      didChange = true;
    }

    if (bounds.top < minTop && element.style.top) {
      element.style.top = `calc(${element.style.top} + ${minTop - bounds.top}px)`;
      didChange = true;
    } else if (bounds.top < minTop) {
      element.style.top = `${minTop - bounds.top}px`;
      didChange = true;
    }

    if (bottom < minBottom && element.style.top) {
      element.style.top = `calc(${element.style.top} - ${minBottom - bottom}px)`;
      didChange = true;
    } else if (bottom < minBottom) {
      element.style.top = `${minBottom - bottom}px`;
      didChange = true;
    }

    if (right < minRight && element.style.left) {
      element.style.left = `calc(${element.style.left} - ${minRight - right}px)`;
      didChange = true;
    } else if (right < minRight) {
      element.style.left = `${minRight - right}px`;
      didChange = true;
    }

    if (didChange && iterations > 0) {
      fitInScreen(element, iterations - 1);
    }
  }

  if (typeof children === "string") {
    //  Fix newline.
    children = children.split(/\r?\n/).map((line, index) => (
      <div key={index}>{line}</div>
    ));
  }

  return createPortal((
    <>
      <div
        ref={element => {
          if (!element) {
            return;
          }

          elementRef.current = element;
          fitInScreen(element);
        }}
        style={style}
        className={cls([styles.tooltip, visible ? styles.visible : null, delay ? styles.delay : null])}
        data-position={position}>
        <div
          ref={element => elementRef.current = element}
          className={styles.text}>
          {children}
        </div>
      </div>

      {
        //  Ghost tooltip allows us to measure the text and set the width on the actual text,
        //  since the rendered text's width can be automatically adjusted at the right edge
        //  of the screen. 
        fixWidth ? (
          <div
            ref={element => {
              if (!element || !elementRef.current) {
                return;
              }

              elementRef.current.style.width = Math.ceil(element.clientWidth) + 'px';
              fitInScreen(elementRef.current);
            }}
            style={{
              ...style,
              left: 0,
              top: 0,
            }}
            className={styles.tooltip}>
            <div
              className={styles.text}>
              {children}
            </div>
          </div>
        ) : null
      }
    </>
  ), document.getElementById('tooltips'));
}