import React, { forwardRef, useState } from 'react';
import cls from 'classnames';
import styles from './image.module.scss';
import TaskIndicator from '../task_indicator';

type Props = {
  /**
   * Whether to transition between `src` changes.
   * @default true
   */
  transitionChange?: boolean;
  /**
   * Whether to transition when the image is loaded.
   * @default true
   */
  transitionLoad?: boolean;
  containerClassName?: string;

  /**
   * Optional max height of the image, in px.
   * 
   * By passing the maximum image height in place of a CSS rule,
   * the image can be contained using `width: 100%` and `height: 100%`.
   * Note that `height: 100%` requires a fixed parent height, which this component
   * assigns automatically.
   */
  maxHeight?: number;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

/**
 * Renders an image and allows for transition effect and more.
 * @author Johan Svensson
 */
const Image: React.FC<Props> = forwardRef(({
  transitionChange = true,
  transitionLoad = true,
  maxHeight,
  containerClassName,
  ...props
}, ref) => {
  const [currentSrc, setCurrentSrc] = useState(props.src);
  const [loaded, setLoaded] = useState(false);

  const transitioning = currentSrc != props.src;

  function clampToMaxHeight(element: HTMLImageElement) {
    if (!element || typeof maxHeight !== 'number') {
      return;
    }

    //  Assign height to parent as we are measuring the image size using `element`.

    element.parentElement.style.height = null;
    if (element.getBoundingClientRect().height > maxHeight) {
      //  See jsdoc of `maxHeight`
      element.parentElement.style.height = maxHeight + 'px';
    }
  }

  return (
    <div
      className={cls([
        cls({
          [styles.transitionable]: transitionChange,
          [styles.transition]: transitioning,
          [styles.loaded]: loaded || !transitionLoad
        }),
        containerClassName,
        styles.container
      ])}>
      <img
        {...props}
        ref={e => {
          if (typeof ref === 'function') {
            ref?.(e);
          }
          clampToMaxHeight(e);
        }}
        style={props.style}
        src={currentSrc}
        onLoad={e => {
          setLoaded(true);
          props.onLoad?.(e);
          clampToMaxHeight(e.currentTarget);
        }}
        onDragStart={e => e.preventDefault()}
        onTransitionEnd={transitioning ? () => setCurrentSrc(props.src) : null} />

      {
        !loaded ? (
          <TaskIndicator type="tiny" animated center />
        ) : null
      }
    </div>
  )
});

export default Image;