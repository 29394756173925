/**
 * @param vat VAT [0 - 100]
 * @return `includingVat` excluding `vat` VAT.
 */
export function excludingVat(includingVat: number, vat: number) {
  if (!vat || isNaN(vat)) {
    return includingVat;
  }

  return includingVat * (1 / (1 + vat * 0.01));
}
/**
 * @param vat VAT [0 - 100]
 * @return `excludingVat` including `vat` VAT.
 */
export function includingVat(excludingVat: number, vat: number) {
  if (!vat || isNaN(vat)) {
    return excludingVat;
  }

  return excludingVat * (1 + vat * 0.01);
}