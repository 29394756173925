//  Polyfills. 
import 'core-js/actual/string/pad-start';
import 'core-js/actual/string/pad-end';
import 'core-js/actual/array/flat'; // iOS 11

import "./log_monitor";
import "./log_timestamps"; // Importing this after log_monitor causes timestamps to be included in monitored logs.

import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment-timezone';
import * as serviceWorker from './serviceWorker';
import './styles/base.scss';
import { RootApp } from './RootApp';
import { resizeElementToWindow, enableViewportLayerAutoSize } from './fit_window';
import { browserName } from 'detect-browser';
import "./utils/tabs";
import { AppType, appType, hostname } from './AppType';
import { setThemeFromHostname } from './utils/theme';
import { isMobile } from './utils/browser';
import { isPrint } from './components_offer/LinkedOfferView/service';
import { currentEnvironment } from './components/dev/environment_indicator';
import { disableSeo } from './utils/seo';
import { getLanguage, setLanguage } from './utils/i18n';

const browser = browserName(window.navigator?.userAgent);
document.body.setAttribute('data-browser', browser);
document.body.setAttribute('data-environment', currentEnvironment());

if (isMobile) {
  document.body.setAttribute('data-mobile', '');
}

const url = new URL(document.location.href);
if (url.searchParams.get('lang')) {
  setLanguage(url.searchParams.get('lang'));
}

ReactDOM.render((
  <RootApp />
), document.getElementById('main'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (appType === AppType.ExpressFlow) {
  const viewportElement = document.querySelector('meta[name="viewport"]');
  if (viewportElement) {
    viewportElement.setAttribute('content', "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"); // Disable pinch-to-zoom.
  }
}

if (!isPrint() && appType !== AppType.ExpressFlow
  && (browser === 'chrome' || appType === AppType.OfferDocument)) {
  //  Some express flow views have height issues with this feature.
  //  In OfferDocument the view needs to fill the app screen.
  resizeElementToWindow();
}

if (appType !== AppType.Main) {
  disableSeo();
}

enableViewportLayerAutoSize();

setThemeFromHostname(hostname, {
  //  In web forms, the theme could have been set already.
  unlessAlreadySet: true,
});