import format from "format-util";
import moment from "moment";
import { apis } from "./backend";
import { enableInteractionLogging } from "./interactions";
import { truncate } from "./utils";
import { getTabId } from "./utils/tabs";
import { getUserId, isSupportLogin } from "./utils/user";
import { hostname } from "./AppType";

const ignore = [
  "Warning: Each child in a list should have a unique \"key\" prop",
  "Warning: React does not recognize the",
  "Warning: validateDOMNesting",
  "Warning: Invalid DOM property",
  "Warning: Unknown event handler property",
  "Warning: Received `true` for a non-boolean attribute",
  "Warning: Received `false` for a non-boolean attribute",
  "React Hook useEffect has a missing dependency",
  "Warning: Encountered two children with the same key"
];

export type ConsoleListener = (log: string) => void;

const listeners: ConsoleListener[] = [];

export function addConsoleListener(listener: ConsoleListener) {
  if (listeners.includes(listener)) {
    return false;
  }

  listeners.push(listener);
  return true;
}

export function removeConsoleListener(listener: ConsoleListener) {
  const index = listeners.indexOf(listener);
  if (index === -1) {
    return false;
  }

  listeners.splice(index, 1);
  return true;
}

/**
 * Returns a list of headers per log file, for helping with debugging.
 */
function listHeaders(): string[] {
  return ["Location: " + document.location.pathname];
}

/**
 * Returns logs saved in the local storage.
 */
function getSavedLogs(): string[] {
  const json = localStorage.getItem('logs');
  if (!json) {
    return [];
  }

  try {
    const object = JSON.parse(json);
    if (!Array.isArray(object)) {
      return [];
    }

    return object;
  } catch (error) {
    return [];
  }
}

/**
 * Saves logs to the local storage.
 * 
 * Unless the logs were sent successfully immediately, we can try again later since the logs are saved.
 */
function saveLogs() {
  //  If missing access token then we still accept logs as anonymous.
  try {
    localStorage.setItem('logs', JSON.stringify(logs));
  } catch (error) {
    console.error("Error saving logs to storage, most likely full");
  }
}

window.addEventListener('beforeunload', saveLogs);

const maxLogCount = 5000;

let logs: string[] = getSavedLogs();

//  Periodically save logs to prevent loss.
setInterval(() => saveLogs(), 5000);

function clearLogs() {
  logs.splice(0);
  listHeaders().forEach(log => addLog(log));
  saveLogs();
}

/**
 * Adds a console log to the list. The list can contain at most `maxLogCount` logs.
 * @param log Log line to add.
 */
function addLog(log: string) {
  if (ignore.find(element => log.includes(element))) {
    return;
  }

  logs.push(truncate(log, 5000));

  if (logs.length > maxLogCount) {
    logs.splice(0, logs.length - maxLogCount);
  }
}

/**
 * Sends logs a server.
 */
async function sendLogs() {
  if (logs.length <= listHeaders().length || isSupportLogin()) {
    return;
  }

  try {
    //  At the moment we are getting "Error: Parse Error" in this endpoint,
    //  thus setting this debug which will appear in access logs.
    const debug = {
      userId: getUserId(),
      logCount: logs.length,
      totalLogLength: logs.reduce((length, log) => length + log.length, 0),
    };

    await apis.users.post('/logs-v1', {
      logs: logs,
      tabId: getTabId(),
      hostname
    }, debug);

    //  Only clear the list if all went OK.
    clearLogs();
  } catch (error) {
    console.error("Error sending logs", error);

    if (error.status === 413) {
      //  Entity too large. Reduce the size to half.
      logs.splice(0, logs.length / 2);
      saveLogs();
    }
  }
}

/**
 * Overrides console.log, console.warn, console.error, console.info, and console.debug.
 * 
 * Logs will be saved to a list and periodically sent to a server.
 */
export function captureConsole() {
  const consoleFunctions = {
    log: console.log.bind(console),
    info: console.info.bind(console),
    warn: console.warn.bind(console),
    error: console.error.bind(console),
    debug: (console.debug || console.log).bind(console)
  };

  for (const fn in consoleFunctions) {
    console[fn] = function () {
      try {
        const optionalParams = [...arguments].slice(1).map(argument => {
          if (argument instanceof Error) {
            //  Some properties tend to not get included when stringified.
            argument = {
              ...argument,
              name: argument.name,
              message: argument.message,
              stack: argument.stack
            };
          }

          if (argument && typeof argument === 'object') {
            //  We don't want [object Object].
            try {
              return JSON.stringify(argument);
            } catch (error) {
              //  For example, Converting circular structure to JSON
              return `[${error.name}: ${error.message}]`;
            }
          }

          return argument;
        });

        let fnPrefix = fn;
        let log = format(arguments[0], ...optionalParams);

        //  Include "alert:" in your console.error log to log it in the application.
        if (fn === "error" && /(\salert|^alert):/i.test(log)) {
          fnPrefix = 'alert';
          log = log.replace('alert: ', ''); //  This part is not needed anymore.
        }

        listeners.forEach(listener => {
          try {
            listener(log);
          } catch (error) {
            console.error("Error invoking console listener", error);
          }
        });

        addLog(fnPrefix + ' ' + log);
      } catch (error) {
        consoleFunctions.error(error);
      }

      consoleFunctions[fn](...arguments);
    };
  }
}

try {
  captureConsole();
} catch (error) {
  console.error("Error capturing console", error);
}

try {
  sendLogs();
  setInterval(() => sendLogs(), 30e3); // The larger the delay the more logs per file.
} catch (error) {
  console.log("Error periodically sending logs", error);
}

function getTimestamp() {
  return moment().format('YYYY-MM-DD HH:mm:ss');
}

document.addEventListener('visibilitychange', function () {
  addLog('document.hidden changed: ' + document.hidden);
});

try {
  //  Log all interactions.
  enableInteractionLogging({
    //  Don't log interactions to the console to make it more discrete.
    onClick: (button, element, path) => addLog(getTimestamp() + " Clicked on " + path),
    onKeyPress: (key, element, path) => {
      if (element instanceof HTMLInputElement && element.type === 'password') {
        //  Exposing the length of a password is a security risk.
        return;
      }

      addLog(getTimestamp() + " Key pressed on " + (path || '<unknown>'))
    },
    onPaste: (text, element, path) => {
      if (element instanceof HTMLInputElement && element.type === 'password') {
        return;
      }

      //  Stringify the text to reveal whitespace and more.
      addLog(getTimestamp() + " Pasted text " + JSON.stringify(text) + " on " + (path || '<unknown>'))
    }
  });
} catch (error) {
  console.error("Error enabling interaction logging", error);
}

export { };