/**
 * Resizes an element to fit the `window.innerHeight` / `window.innerWidth`.
 */
export function resizeElementToWindow(element = document.getElementById('main')) {
  element.style.height = window.innerHeight + 'px';
  window.addEventListener('scroll', e => element.style.height = window.innerHeight + 'px');
}

/**
 * To fix problems regarding viewport layers' bounds,
 * such as exceeding the screen height on iOS when the keyboard is open,
 * each viewport will automatically fit `window.visualViewport`. 
 */
export function enableViewportLayerAutoSize(viewports = document.querySelectorAll('.viewport-layer')) {
  if (!window.visualViewport) {
    window.addEventListener('resize', () => {
      viewports.forEach(viewport => {
        const element = viewport as HTMLElement;
        if (!element.isConnected) {
          return;
        }

        element.style.height = window.innerHeight + 'px';
      });
    });

    return;
  }

  //  visualViewport is more reliable on iOS. window.innerHeight might not update when opening the keyboard.
  window.visualViewport.addEventListener('resize', () => {
    viewports.forEach(viewport => {
      const element = viewport as HTMLElement;
      if (!element.isConnected) {
        return;
      }

      element.style.top = window.visualViewport.offsetTop + 'px';
      element.style.height = (window.visualViewport.height * window.visualViewport.scale) + 'px';
    });
  });
}