/**
 * Creates an instance of `window.ResizeObserver` and falls back to a polyfill. 
 */
export async function createResizeObserver(callback: ResizeObserverCallback): Promise<ResizeObserver> {
  if (window.ResizeObserver) {
    return new window.ResizeObserver(callback);
  }

  //  Polyfill required for iOS 11.
  return await import("resize-observer-polyfill").then(
    ({ default: ResizeObserver }) => new ResizeObserver(callback));
}