import React, { CSSProperties, HTMLAttributes, useEffect, useLayoutEffect, useRef, useState } from 'react';
import cls from 'classnames';
import styles from './transitioned_container.module.scss';
import { createResizeObserver } from '../../../utils/polyfills';
import { isEventFromCurrentTarget } from '../../../utils/dom';

// `none` can transition width / height but does not fade children or even render the previous children.
// `disabled` is for conveniently disabling transitioning altogether, same as if `lock` is unchanged.
export type TransitionType = 'none' | 'fade' | 'fade-slide' | 'slide-left' | 'slide-right' | 'slide-up' | 'slide-down' | 'disabled';
export type TransitionIntensity = 'normal' | 'high';

export type TransitionedContainerProps = {
  children?: any;

  /**
   * `style` applied to each container of previous or next children.
   */
  transitionContainerStyle?: CSSProperties;

  /**
   * `className` applied to each container of previous or next children.
   */
  transitionContainerClassName?: string;

  /**
   * If provided, then transition only when the key changes.
   */
  lock?: any;

  transitionWidth?: boolean;
  transitionHeight?: boolean;

  transformBounds?: (width: number, height: number) => { width: number, height: number };

  /**
   * Whether to cut content overflow during transitioning.
   * Set this to `false` if you have content which stretch beyond its parent's bounds.
   * @default true
   */
  overflowHiddenDuringTransition?: boolean;

  transition?: TransitionType;
  intensity?: TransitionIntensity;

  /**
   * Whether to use a much slower transitioning speed to debug problems.
   * `preserve` to preserve transition state if there are problems during transition only.
   */
  debug?: 'slow' | 'very-slow' | 'preserve';
} & Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className'>;

/**
 * Transitions layout and children changes.
 */
export const TransitionedContainer: React.FC<TransitionedContainerProps> = ({
  children,
  lock,
  transitionWidth = true,
  transitionHeight = true,
  transition = 'fade',
  intensity = 'normal',
  overflowHiddenDuringTransition = true,
  style,
  transitionContainerStyle,
  transitionContainerClassName,
  className,
  transformBounds,
  debug
}) => {
  const previousLock = useRef(null);

  const [previousChildren, setPreviousChildren] = useState(null);
  const checkedLockAtleastOnce = useRef(null); // Prevents transitioning immediately on mount which can cause visual issues, especially for nested TransitionedContainer.
  const previousChildrenRef = useRef(null);
  const resizeObserver = useRef<ResizeObserver>(null);

  const [styleState, setStyleState] = useState<'start' | 'end'>('end');
  const widthStart = useRef(0); //  Width before transition.
  const heightStart = useRef(0); // Height before transition.
  const widthEnd = useRef(0); // Width after transition.
  const heightEnd = useRef(0);  // Height after transition.
  const isTransitioning = useRef(false);

  function hasLockChanged() {
    if (Array.isArray(lock)) {
      return JSON.stringify(lock) !== JSON.stringify(previousLock.current);
    }

    return lock !== previousLock.current;
  }

  //  useLayoutEffect prevents an unwanted render cycle resulting in minor stuttering.
  useLayoutEffect(() => {
    if (!checkedLockAtleastOnce.current) {
      previousLock.current = lock;
      previousChildrenRef.current = children;
      checkedLockAtleastOnce.current = true;
      return;
    }

    if (hasLockChanged()) {
      if (previousChildrenRef.current && transition !== "disabled") {
        setPreviousChildren(previousChildrenRef.current);
      }

      previousLock.current = lock;
    }

    previousChildrenRef.current = children;
  }, [children]);

  //  This will be true even before the useLayoutEffect.
  const isTransitioningJustStarted = checkedLockAtleastOnce.current && hasLockChanged();
  isTransitioning.current = Boolean(previousChildren) || isTransitioningJustStarted;

  useEffect(() => {
    if (isTransitioning.current) {
      setStyleState('start'); // Trigger a CSS transition.

      //  styleState briefly sets the from and to CSS values to trigger a transition.
      setTimeout(() => setStyleState('end'), 1);
    }
  }, [isTransitioning.current]);

  useEffect(() => {
    return () => {
      resizeObserver.current?.disconnect();
    }
  }, []);

  return (
    <div
      className={cls([
        cls({
          [styles.container]: true,
          [styles.transitioning]: isTransitioning.current,
          [styles.overflowHiddenDuringTransition]: overflowHiddenDuringTransition,
          [styles.transitionWidth]: transitionWidth,
          [styles.transitionHeight]: transitionHeight,
        }),
        className
      ])}
      data-debug={debug}
      data-type={transition}
      data-intensity={intensity}
      style={{
        width: isTransitioning.current ? (styleState === "start" ? widthStart.current : widthEnd.current) : null,
        height: isTransitioning.current ? (styleState === "start" ? heightStart.current : heightEnd.current) : null,
        ...style
      }}>
      <div
        className={cls([styles.children, styles.next, transitionContainerClassName])}
        style={transitionContainerStyle}
        data-transition-position="next"
        ref={element => {
          if (!element) {
            return;
          }

          function onSizeChange() {
            const bounds = element.getBoundingClientRect();
            let nextWidth = bounds.width;
            let nextHeight = bounds.height;

            if (typeof transformBounds === "function") {
              const transformed = transformBounds(nextWidth, nextHeight);
              nextWidth = transformed.width;
              nextHeight = transformed.height;
            }

            if (!isTransitioning.current) {
              widthStart.current = nextWidth;
              heightStart.current = nextHeight;
            }

            widthEnd.current = nextWidth;
            heightEnd.current = nextHeight;
          }

          onSizeChange();

          if (resizeObserver.current) {
            resizeObserver.current.disconnect();
            resizeObserver.current.observe(element);
          } else {
            createResizeObserver(() => onSizeChange())
              .then(observer => {
                observer.observe(element);
                resizeObserver.current = observer;
              });
          }
        }}>
        {children}
      </div>
      {
        previousChildren ? (
          <div
            className={cls([styles.children, styles.previous, transitionContainerClassName])}
            style={transitionContainerStyle}
            data-transition-position="previous"
            onAnimationEnd={event => {
              if (isEventFromCurrentTarget(event) && debug !== "preserve") {
                setPreviousChildren(null);
              }
            }}>
            {previousChildren}
          </div>
        ) : null
      }
    </div>
  )
}