/**
 * Entity permissions for a user or group. 
 */
export default class UserPermissions {
  calendar?: CalendarPermissions;
}

export type UserPermissionsSubject = keyof UserPermissions;

export type CalendarPermissions = {
  [userId: string]: UserCalendarAccess;
};

export enum UserCalendarAccess {
  ReadOnly = 'readOnly',
  FullEdit = 'fullEdit'
}

export function compareUserCalendarAccess(a: UserCalendarAccess, b: UserCalendarAccess) {
  if (a == b) {
    return 0;
  }

  if (a == UserCalendarAccess.FullEdit) {
    return -1;
  }

  if (b == UserCalendarAccess.FullEdit) {
    return 1;
  }

  if (a == UserCalendarAccess.ReadOnly) {
    return -1;
  }

  if (b == UserCalendarAccess.ReadOnly) {
    return 1;
  }

  return 0;
}

export type UserPermission = boolean | null | UserCalendarAccess;