import UploadedFile from "./UploadedFile";

/**
 * Represents a user's salary model.
 * @author Johan Svensson
 */
export default class SalaryModel {
  type = SalaryType.Fixed;

  fixed: number;
  minimumWage: number;

  /**
   * Commission [0 - 100%].
   */
  commission: number;

  files?: UploadedFile[] = [];

  constructor(deriveFrom?: Partial<SalaryModel>) {
    if (!deriveFrom) {
      return;
    }

    Object.assign(this, deriveFrom);
  }

  /**
   * @returns Whether this salary accounts for commission.
   */
  get isCommissionBased() {
    if (this.type === SalaryType.CommissionOnly || this.type === SalaryType.CommissionMinimumWage) {
      return true;
    }

    if (this.type === SalaryType.Fixed && this.commission > 0) {
      return true;
    }

    return false;
  }
}

export enum SalaryType {
  Fixed = 'fixed',
  CommissionOnly = 'commissionOnly',
  CommissionMinimumWage = 'commissionMinimumWage'
};