import React, { createContext, useEffect, useState } from "react";
import { AppType, appType } from "./AppType";
import { getI18n, setI18nSchema } from "./utils/i18n";
import { configBase } from "./utils/theme";

/**
 * Loads an internationalization file before rendering children.
 */
export const I18nLoader: React.FC = ({ children }) => {
  const [isFinished, setFinished] = useState(false);
  const [nonce, setNonce] = useState(0);

  async function refresh() {
    try {
      const urls: string[] = [
        new URL('i18n', configBase).href
      ];

      if (appType === AppType.Main) {
        urls.push(new URL('./i18n_default', configBase).href);
      }

      const schemas = await Promise.all(urls.map(url =>
        fetch(url).then(response => response.text())));

      setI18nSchema(schemas.join('\n\n'));
    } catch (error) {
      console.error(error);
    }

    setFinished(true);
  }

  useEffect(() => {
    refresh();
  }, [])

  if (isFinished) {
    return (
      <I18nContext.Provider value={{
        replaceTranslations(args) {
          args.forEach(args => getI18n().replace(
            args.originalLanguagePhrase,
            args.newPhrase,
            args.language
          ));

          setNonce(Date.now()); //  Re-render everything.
        },
      }}>
        {children}
      </I18nContext.Provider>
    );
  }

  //  Assume preload is shown here.
  return null;
}

/**
 * Parameters for i18n.replace.
 */
export type ReplacedTranslation = {
  originalLanguagePhrase: string,
  newPhrase: string,
  language?: string;
};

export const I18nContext = createContext<{
  replaceTranslations: (args: ReplacedTranslation[]) => void;
}>(null);