import React from 'react';
import cls from 'classnames';
import './styles.scss';
import { SwitchProps } from '../Switch/Switch';

export interface CheckboxProps extends SwitchProps {
  type?: 'checkbox' | 'radio';
}

/**
 * A smaller variant of the switch component.
 * @author Johan Svensson
 */
export default function Checkbox({ checked, disabled, onChange, type = 'checkbox' }: CheckboxProps) {
  return (
    <input
      type="checkbox"
      className={cls({
        "checkbox": true,
        "disabled": disabled,
        ["type-" + type]: true
      })}
      checked={checked}
      disabled={disabled}
      onChange={event => onChange?.(event.currentTarget.checked, event)} />
  )
}